import { render, staticRenderFns } from "./PageNotFound.vue?vue&type=template&id=3d2ec509&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\Youshop\\laravel\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d2ec509')) {
      api.createRecord('3d2ec509', component.options)
    } else {
      api.reload('3d2ec509', component.options)
    }
    module.hot.accept("./PageNotFound.vue?vue&type=template&id=3d2ec509&", function () {
      api.rerender('3d2ec509', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/PageNotFound.vue"
export default component.exports