import { render, staticRenderFns } from "./BasicInfo.vue?vue&type=template&id=7c901381&scoped=true&"
import script from "./BasicInfo.vue?vue&type=script&lang=js&"
export * from "./BasicInfo.vue?vue&type=script&lang=js&"
import style0 from "./BasicInfo.vue?vue&type=style&index=0&id=7c901381&scoped=true&lang=css&"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c901381",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\Youshop\\laravel\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c901381')) {
      api.createRecord('7c901381', component.options)
    } else {
      api.reload('7c901381', component.options)
    }
    module.hot.accept("./BasicInfo.vue?vue&type=template&id=7c901381&scoped=true&", function () {
      api.rerender('7c901381', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/product/BasicInfo.vue"
export default component.exports