<template>
<!--begin::Header-->
    <div id="tc_header" class="header header-fixed">
        <!--begin::Container-->
        <div class="container-fluid d-flex align-items-stretch justify-content-between">
            <!--begin::Header Menu Wrapper-->
            <div class="header-menu-wrapper header-menu-wrapper-left" id="tc_header_menu_wrapper">
                <!--begin::Header Menu-->
                <div id="tc_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
                    <!--begin::Header Nav-->
                    <ul class="menu-nav">

                            <li class="menu-item menu-item-open menu-item-here menu-item-submenu menu-item-rel menu-item-open menu-item-here menu-item-active p-0"
                            data-menu-toggle="click" aria-haspopup="true" @click="setBurgerMenu()">
                            <!--begin::Toggle-->
                            <div class="btn  btn-clean btn-dropdown mr-0 p-0" id="tc_aside_toggle">
                                <span class="svg-icon svg-icon-xl svg-icon-primary">

                                    <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-list"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                </span>
                            </div>
                            <!--end::Toolbar-->
                        </li>

                    </ul>
                    <!--end::Header Nav-->
                </div>
                <!--end::Header Menu-->
            </div>
            <!--end::Header Menu Wrapper-->
            <!--begin::Topbar-->
            
            <div class="topbar">
                <a href="" @click="lang()" >
                    <span class="svg-icon svg-icon-xl svg-icon-primary mr-4" title="Switch language">
                        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="22" height="25" aria-hidden="true" focusable="false" viewBox="0 0 512 593.282">
                            <path fill="none" d="m12045.7 24348.4-9327.4 3292V7339.5l9327.4 3017.7v13991.2" style="fill:#0a0a0a;fill-opacity:0;fill-rule:nonzero;stroke-width:500;stroke:#ae69f5" transform="matrix(.02123 0 0 -.02123 0 593.282)"/>
                            <path d="m11821.8 24358.6 9684.5 3291.9V7349.6l-9684.5 3017.7v13991.3M299.602 3785.7 11821.8 7626.2v16734.6L299.602 20520.3V3785.7" class="header-background-fill" style="fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.02123 0 0 -.02123 0 593.282)"/>
                            <path d="m17072.1 3532.9 1633-2686.599L19566.2 3342ZM4217.4 18592.5c-61.2 60.1 79.7-491.1 275.8-689.4 347.7-350.8 619.3-396 763.9-401.8 320-12.8 714.9 79.7 949.4 178 226.9 96.8 624.5 299.8 775 595.9m3049.5-6271.9c-90.8 33-1969.4 811.2-2235.7 938.7-217.9 104.8-752.2 330.7-1003.6 433.3 708.1 1091.8 1155.1 1915.7 1214.6 2041.2 110.1 229.6 859.6 1696.2 877.1 1786.5 17 91.5 38.3 429.5 21.8 509.8-16.5 81.9-291.3-75.5-664.4-202-373.7-126-1083.9-587.9-1358.2-645.8-275.3-57.4-1155.1-390.7-1605.3-540.1-450.2-149.4-1301.8-409.3-1652.1-503.9-350.8-94.6-657-102.1-853.2-161.6 0 0 26.1-274.8 78.2-357.2 51.5-82.4 237.1-284.4 452.9-340.8 215.8-56.8 573-34 735.7 3.2 162.6 37.8 444.3 175.4 482.1 235.5 38.2 61.1-19.7 249.3 44.6 306.2 64.9 56.3 922.3 256.7 1246 354.5 323.7 99.5 1562.8 526.3 1730.8 504.5-53.2-176.5-1049.9-2150.7-1370.9-2739.7-321.1-588.9-2186.3-3179.8-2583.4-3636.4-301.4-347.1-1031.8-1235.3-1284.8-1435.7 63.8-17.6 516.1 21.2 598.5 72.2 513.5 316.3 1368.8 1381 1644.2 1705.3 818.6 960 1537.8 1968.4 2108.1 2833.8h.6c111.1-46.3 1009.4-778.2 1243.8-940.4 234.4-162.1 1159.4-678.2 1359.8-763.8 200.4-86.7 970.6-441.8 1003-321.6 32.4 121.2-139.3 829.8-230.2 864.3" style="fill:#ae69f5;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.02123 0 0 -.02123 0 593.282)"/>
                            <path d="M5690 2220c180-110 350-200 540-290 380-190 810-390 1220-540 560-210 1120-380 1680-510 310-70 650-130 980-180 30 0 920-110 1100-110h900c350 30 680 50 1030 100 280 40 590 90 890 160 220 50 450 100 670 170 210 60 450 140 680 220 150 50 310 120 470 180 130 60 290 130 440 190 180 80 390 190 590 290 160 80 340 180 510 280 130 70 430 300 590 300 180 0 300-160 300-300 0-290-390-380-570-510-190-130-420-230-620-340-400-210-810-390-1200-540-510-190-1070-370-1570-490-190-40-380-90-570-120-100-20-1140-180-1430-180h-1320c-350 30-720 70-1070 120-310 50-640 110-950 180-240 50-500 120-730 190-400 110-790 250-1170 400-690 260-1410 600-2090 1050-120 80-130 160-130 250 0 150 110 290 290 290 160 0 480-230 540-260m6430 22180V7600c-10-50-30-100-70-150-20-30-60-70-90-80C11710 7270 450 3490 300 3490c-120 0-230 80-290 210 0 10-10 20-10 40v16810c20 50 30 120 70 160 80 110 220 130 310 160 170 60 11260 3780 11420 3780 100 0 320-70 320-250zm-610-16550L610 4220v16080l10900 3630V7850" style="fill:#ae69f5;fill-opacity:1;fill-rule:evenodd;stroke:none" transform="matrix(.02123 0 0 -.02123 0 593.282)"/>
                            <path d="M21810 27620V7390c-10-230-170-330-320-330-130 0-1070 320-1230 370-1260 390-2530 780-3780 1170-280 90-570 180-840 270-240 70-500 150-740 230-1070 330-2160 660-3230 1020-40 10-140 150-140 180v14130c20 50 40 110 90 150 80 90 3510 1230 4860 1680 360 130 4870 1680 5010 1680 180 0 320-130 320-320zm-610-19850-9070 2820v13550l9070 3080V7770" style="fill:#ae69f5;fill-opacity:1;fill-rule:evenodd;stroke:none" transform="matrix(.02123 0 0 -.02123 0 593.282)"/>
                            <path d="M24112.1 3532.9 11995 7395l50.7 16813 12066.4-3840.5V3532.9" style="fill:#ae69f5;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.02123 0 0 -.02123 0 593.282)"/>
                            <path d="m17289.8 19408.1 1561.1-472.9L21695 8685l-1603.5 486.5-576.1 2104.3-3313.8 1004.4L15489 10566l-1604 486.6zm713.7-2713.6-1189.3-2874.6 2186.5-662.8z" class="header-background-fill" style="fill-opacity:1;fill-rule:evenodd;stroke:none" transform="matrix(.02123 0 0 -.02123 0 593.282)"/>
                        </svg>
                    </span>
                </a>
                <div class="posicon d-lg-block d-none" v-if="$parent.permissions.includes('pos')">
                    <router-link to="/admin/pos" class="btn btn-primary white mr-2">POS</router-link>
                </div>
                <div class="posicon d-lg-block d-none">
                    <div class="dropdown" :class="{ 'show': showdropdown }">

								<div class="topbar-item" data-toggle="dropdown" data-display="static" :aria-expanded="showdropdown" @click="showDropDown()">
									<div class="btn btn-icon w-auto btn-clean d-flex align-items-center pr-1 pl-3">
										<span class="text-dark-50 font-size-base d-none d-xl-inline mr-3">
                                            {{ username }}</span>
										<span class="symbol symbol-35 symbol-light-success" >
											<span class="symbol-label font-size-h5 " >
												<svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
												</svg>
											</span>
										</span>
									</div>
								</div>

								<div class="dropdown-menu dropdown-menu-right" :class="{ 'show': showdropdown }" style="min-width: 150px;">

									<router-link to="/admin/profile" class="dropdown-item">
										<span class="svg-icon svg-icon-xl svg-icon-primary mr-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
												<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
												<circle cx="12" cy="7" r="4"></circle>
											</svg>
										</span>
										Edit Profile
									</router-link>

									<a href="#" class="dropdown-item" @click="logOut()">
										<span class="svg-icon svg-icon-xl svg-icon-primary mr-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-power">
												<path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
												<line x1="12" y1="2" x2="12" y2="12"></line>
											</svg>
										</span>
										Logout
									</a>
								</div>

							</div>
                </div>

            </div>
            <!--end::Topbar-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Header-->
</template>



<script>
export default {
   data(){
       return {
           showdropdown:false,
           username:""
       }
       
   },
    methods: {
        logOut() {
            this.$parent.loading = true;
            let page_url = "/api/admin/logout";

            axios.post(page_url,{},this.token).then(res => {
                this.orders = res.data.data;
                setTimeout(() => { this.$router.push('/admin/login'); }, 1000);
            })
        },
        lang() {
            this.$parent.loading = true;
            let page_url = "/api/admin/lang";
            let id;
            axios.post(page_url,{},this.token).then(res => {
                id = res.data.message;
                axios
                    .post("/api/admin/language/is_default?is_default=1&id="+id, {}, this.token)
                    .then((res) => {
                    if (res.data.status == "Success") {
                        this.display_form = 0;
                        this.$toaster.success(res.data.message);
                        this.fetchLanguages();
                        this.clearForm();
                        // this.fetchLanguages();
                    } else {
                        this.$toaster.error(res.data.message);
                    }
                    })
                // location.reload();
            })
                
        },
        setBurgerMenu(){
            
            var classList = document.body.classList.value.split(' ');
            console.log(classList);
            if(classList.indexOf('aside-minimize') === -1)
                document.body.classList.add('aside-minimize')
            else
                document.body.classList.remove('aside-minimize')

            this.$emit('setBurgerMenu');
        },
        showDropDown(){
            this.showdropdown = !this.showdropdown
        }
    },
    mounted() {
     
        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        console.log(localStorage.getItem('name'));
        if(localStorage.getItem('name')){

            this.username = localStorage.getItem('name')
        }
    },
};
</script>
