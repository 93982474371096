var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card card-custom gutter-b bg-transparent shadow-none border-0",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header align-items-center border-bottom-dark px-0",
                      },
                      [
                        _c("div", { staticClass: "card-title mb-0 w-100" }, [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "card-label mb-0 font-weight-bold text-body",
                            },
                            [
                              _vm._v(
                                "\r\n                                            Newsletter Contact\r\n                                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary white p-2 ml-2",
                              staticStyle: { float: "right" },
                              attrs: { type: "button", title: "Add New" },
                              on: {
                                click: function ($event) {
                                  return _vm.exportNewsletter()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\r\n                                            Export\r\n                                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.linkShow
                            ? _c(
                                "a",
                                {
                                  staticClass: "download",
                                  staticStyle: { float: "right" },
                                  attrs: {
                                    href: "/export/newslettercontact.csv",
                                  },
                                },
                                [_vm._v("Download Link")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "table-responsive",
                            attrs: { id: "printableTable" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dataTables_wrapper no-footer",
                                attrs: { id: "productbrandTable_wrapper" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_length",
                                    attrs: { id: "productbrandTable_length" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Show\r\n                                                        "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.limit,
                                              expression: "limit",
                                            },
                                          ],
                                          attrs: {
                                            name: "productbrandTable_length",
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.limit = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function ($event) {
                                                return _vm.fetchNewsletter()
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "10" } },
                                            [_vm._v("10")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "25" } },
                                            [_vm._v("25")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "50" } },
                                            [_vm._v("50")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "100" } },
                                            [_vm._v("100")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "200" } },
                                            [_vm._v("200")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "500" } },
                                            [_vm._v("500")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "1000" } },
                                            [_vm._v("1000")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" entries"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_filter",
                                    attrs: { id: "productbrandTable_filter" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v("Search:"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchParameter,
                                            expression: "searchParameter",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder: "",
                                        },
                                        domProps: {
                                          value: _vm.searchParameter,
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.fetchNewsletter()
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.searchParameter =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    this.searchParameter != ""
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-light font-weight-bold mr-1 mb-1",
                                            staticStyle: {
                                              padding:
                                                "0.375rem 0.75rem !important",
                                            },
                                            on: { click: _vm.clearSearch },
                                          },
                                          [_vm._v(" Clear ")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass: "display dataTable no-footer",
                                    attrs: {
                                      id: "productbrandTable",
                                      role: "grid",
                                    },
                                  },
                                  [
                                    _c("thead", { staticClass: "text-body" }, [
                                      _c("tr", { attrs: { role: "row" } }, [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "id"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "id"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "31.25px" },
                                            attrs: {
                                              tabindex: "0",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sorting("id")
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                                                ID\r\n                                                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "name"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "name"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "95.5288px" },
                                            attrs: {
                                              tabindex: "0",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label":
                                                "brand: activate to sort column ascending",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sorting("name")
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                                                Name\r\n                                                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            staticStyle: { width: "53.1891px" },
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                                                Email\r\n                                                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            staticStyle: { width: "53.1891px" },
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                                                Date\r\n                                                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            staticStyle: { width: "53.1891px" },
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                                                Action\r\n                                                            "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      {
                                        staticClass: "kt-table-tbody text-dark",
                                      },
                                      _vm._l(
                                        _vm.newsletters,
                                        function (newsletter) {
                                          return _c(
                                            "tr",
                                            {
                                              key: newsletter.newslettercontact_id,
                                              staticClass:
                                                "kt-table-row kt-table-row-level-0 odd",
                                              attrs: { role: "row" },
                                            },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "sorting_1" },
                                                [
                                                  _vm._v(
                                                    "\r\n                                                                " +
                                                      _vm._s(
                                                        newsletter.newslettercontact_id
                                                      ) +
                                                      "\r\n                                                            "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(
                                                      newsletter.newslettercontact_name
                                                    ) +
                                                    "\r\n                                                            "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(
                                                      newsletter.newslettercontact_email
                                                    ) +
                                                    "\r\n                                                            "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(newsletter.date) +
                                                    "\r\n                                                            "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _vm.$parent.permissions.includes(
                                                "NewsletterContact-delete"
                                              )
                                                ? _c("td", [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "javascript:void(0)",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteNewsletter(
                                                              newsletter.newslettercontact_id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-trash",
                                                        }),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "pagination pagination-sm m-0 float-right",
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.prev_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link",
                                            attrs: {
                                              href: "javascript:void(0)",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchNewsletter(
                                                  _vm.pagination.prev_page_url
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Previous")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "disabled" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "page-link text-dark",
                                          attrs: { href: "javascript:void(0)" },
                                        },
                                        [
                                          _vm._v(
                                            "Page " +
                                              _vm._s(
                                                _vm.pagination.current_page
                                              ) +
                                              " of " +
                                              _vm._s(_vm.pagination.last_page)
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "page-item",
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.next_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link",
                                            attrs: {
                                              href: "javascript:void(0)",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchNewsletter(
                                                  _vm.pagination.next_page_url
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Next")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }